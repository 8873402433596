import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]
  static values = {
    limit: Number
  }

  calculate() {
    const editor = event.target.editor
    const document = editor.getDocument()
    const characterCount = document.toString().length

    this.messageTarget.innerHTML = `${characterCount}/${this.limitValue} characters`
  }
}
