import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["log"]

  connect() {
    // Start polling at 1 second interval
    this.timer = setInterval(() => {
      this.refresh()
    }, 1000)
  }

  refresh() {
    fetch(this.data.get("url"))
      .then(blob => blob.json())
      .then(status => {
        // Update log and auto-scroll to the bottom
        this.logTarget.innerText = status.log
        this.logTarget.scrollTop = this.logTarget.scrollHeight

        if (status.error) {
          this.stopRefresh()
          // Add additional error handling as needed
        } else if (status.completed) {
          this.stopRefresh()
        }
      })
  }

  stopRefresh() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  disconnect() {
    this.stopRefresh()
  }
}
