import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["selected"]
  static targets = ["errors", "card", "cardList", "button", "formField"]

  setPaymentMethod(event) {
    const paymentMethod = event.params.paymentMethod

    this.cardTargets.forEach(card => { card.classList.remove(this.selectedClass) })
    event.currentTarget.classList.add(this.selectedClass)

    this.buttonTarget.disabled = false
    this.formFieldTarget.value = paymentMethod
  }

  disableCards() {
    this.cardTargets.forEach(card => {
      card.disabled = true
      card.classList.add("disabled")
    })
  }

  enableCards() {
    this.cardTargets.forEach(card => {
      card.disabled = false
      card.classList.remove("disabled")
    })
  }

  displayErrors(message) {
    this.errorsTarget.style.display = "block"
    this.errorsTarget.textContent = message
  }

  resetErrors() {
    this.errorsTarget.style.display = "none"
  }

  loading() {
    this.buttonTarget.disabled = true
    this.buttonTarget.querySelector(".spinner").classList.remove("hidden")
    this.buttonTarget.querySelector(".text").classList.add("hidden")
  }
}
