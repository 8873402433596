
document.addEventListener("turbo:load", () => {
  const cardElement = document.querySelector("#card-element")

  if (cardElement !== null) { setupStripe() }

  const newCard = document.querySelector("#use-new-card")
  if (newCard !== null) {
    newCard.addEventListener("click", (event) => {
      event.preventDefault()
      document.querySelector("#payment-form").classList.remove("hidden")
      document.querySelector("#existing-card").classList.add("hidden")
    })
  }
})

function setupStripe() {
  const stripeKey = document.querySelector("meta[name='stripe-key']").getAttribute("content")
  const stripe = window.Stripe(stripeKey)

  const elements = stripe.elements()
  const card = elements.create("card")
  card.mount("#card-element")

  const form = document.getElementById("payment-form")
  const displayError = document.getElementById("card-errors")

  const paymentIntentId = form.dataset.paymentIntent
  const setupIntentId = form.dataset.setupIntent

  card.addEventListener("change", (event) => {
    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ""
    }
  })

  if (paymentIntentId) {
    if (form.dataset.status === "requires_action") {
      stripe.confirmCardPayment(paymentIntentId, { setup_future_usage: "off_session" }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector("#card-details").classList.remove("hidden")
        } else {
          form.submit()
        }
      })
    }
  }

  form.addEventListener("submit", (event) => {
    event.preventDefault()

    const name = form.querySelector("#name_on_card").value
    const data = {
      payment_method_data: {
        card: card,
        billing_details: {
          name: name,
        }
      }
    }

    if (paymentIntentId) {
      // complete payment intent
      stripe.confirmCardPayment(paymentIntentId, {
        payment_method: data.payment_method_data,
        setup_future_usage: "off_session",
        save_payment_method: true,
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector("#card-details").classList.remove("d-none")
        } else {
          form.submit()
        }
      })
    } else if (setupIntentId) {
      // update a card or subscribe w/ trial (using SetupIntent)
      stripe.confirmCardSetup(setupIntentId, {
        payment_method: data.payment_method_data,
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          addHiddenField(form, "payment_method_id", result.setupIntent.payment_method)
          form.submit()
        }
      })
    } else {
      // subscribe with no trial
      data.payment_method_data.type = "card"
      stripe.createPaymentMethod(data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          addHiddenField(form, "payment_method_id", result.paymentMethod.id)
          form.submit()
        }
      })
    }
  })

  function addHiddenField(form, name, value) {
    const hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)

    form.appendChild(hiddenInput)
  }
}
