import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["delay", "button"]

  connect () {
    const duration = parseInt(this.data.get("duration"))

    if (duration > 0) {
      const delay = duration * 1000

      this.buttonTargets.forEach(button => {
        button.setAttribute("disabled", true)
        button.classList.add("disabled")
      })

      this.timer = setInterval(() => {
        this.enableButtons()
      }, delay)
    }
  }

  enableButtons () {
    this.buttonTargets.forEach(button => {
      button.removeAttribute("disabled")
      button.classList.remove("disabled")
    })
  }

  disconnect () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
