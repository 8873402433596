import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entry", "searchField"]

  filter() {
    const searchTerm = event.target.value.toLowerCase()

    if (searchTerm == "") {
      this.reset()
    } else {
      this.entryTargets.forEach(entry => {
        const entryTerm = entry.dataset.searchTerm.toLowerCase()

        if (entryTerm.includes(searchTerm)) {
          entry.style.display = ""
        } else {
          entry.style.display = "none"
        }
      })
    }
  }

  reset() {
    this.entryTargets.forEach(entry => entry.style.display = "")
    this.searchFieldTarget.value = ""
  }
}
