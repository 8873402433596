import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["star", "input"]

  set() {
    const selectedStar = event.target
    const rating = selectedStar.dataset.rating

    this.reset()

    this.setStars(rating)
    this.inputTarget.value = rating
  }

  reset() {
    this.starTargets.forEach(star => star.innerHTML = "☆")
  }

  setStars(rating) {
    for (let i = 1; i <= rating; i = i + 1) {
      this.element.querySelector(`[data-rating="${i}"]`).innerHTML = "★"
    }
  }
}
