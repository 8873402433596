import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {

  navigate() {
    const state = event.target
    const url = state.dataset.url
    Turbo.visit(url)
  }
}
