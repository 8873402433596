import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox"]
  static values = {
    formFieldName: String
  }

  hideCheckboxes() {
    this.checkboxTargets.forEach(checkbox => checkbox.style.display = "none")
  }

  toggleCheckAll() {
    const checkAllCheckbox = event.target

    if (checkAllCheckbox.checked) {
      this.checkboxTargets.forEach(checkbox => {
        if (!checkbox.checked) {
          checkbox.click()
        }
      })
    } else {
      this.checkboxTargets.forEach(checkbox => {
        if (checkbox.checked) {
          checkbox.click()
        }
      })
    }
  }

  provision() {
    const checkbox = event.target
    const value = checkbox.value
    const identifier = `${this.formFieldNameValue}${value}`
    const existingField = document.getElementById(identifier)

    if (checkbox.checked) {
      const hiddenInput = document.createElement("input")
      hiddenInput.setAttribute("type", "hidden")
      hiddenInput.setAttribute("name", this.formFieldNameValue)
      hiddenInput.setAttribute("value", value)
      hiddenInput.setAttribute("id", identifier)

      this.formTarget.appendChild(hiddenInput)
    } else if (existingField) {
      existingField.remove()
    }
  }
}
