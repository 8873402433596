import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"

export default class extends Controller {

  connect() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const searchTerm = urlParams.get("search")

    if (searchTerm !== "" && searchTerm !== null && searchTerm !== undefined) {
      this.highlight(this.element, searchTerm)
    }
  }

  highlight(node, term) {
    const instance = new Mark(node)
    instance.mark(term, {
      separateWordSearch: true,
      accuracy: "partially",
      className: "highlight"
    })
  }
}
