import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.element.setAttribute("autocomplete", "none")

    const config = {
      valueField: "id",
      labelField: "name",
      searchField: ["name", "email", "username", "id"],
      selectOnTab: true,
      openOnFocus: true,
      load: this.loadOptions,
      render: {
        option: this.renderOption,
        item: this.renderItem
      }
    }

    new TomSelect(this.element, config)
  }

  renderItem(user, escape) {
    return `
    <div>${escape(user.name)}</div>`
  }

  renderOption(user, escape) {
    var secondaryInfo

    if (user.email === user.username) {
      secondaryInfo = escape(user.email)
    } else {
      secondaryInfo = `${escape(user.email)} / ${escape(user.username)}`
    }
    return `
    <div>
      ${escape(user.id)} - ${escape(user.name)} <small><i>${escape(user.user_type)}</i><small><br>
      ${secondaryInfo}
    </div> `
  }

  loadOptions(query, callback) {
    const url = `${this.input.dataset.url}?search=${encodeURIComponent(query)} `

    fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json)
      }).catch(() => {
        callback()
      })
  }
}


