import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const id = event.item.dataset.id
    const sortUrl = this.urlValue.replace(":id", id)
    const data = new FormData()

    data.append("position", event.newIndex + 1)

    patch(sortUrl, { body: data })
  }
}
