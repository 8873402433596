import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  acknowledge() {
    this.createHideCookie(this.idValue)
    this.hideAlert()
  }

  createHideCookie(name, days = 365) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    const expires = date.toGMTString()

    document.cookie = `${name}=hidden; expires=${expires}; path=/`
  }

  hideAlert() {
    this.element.remove()
  }
}
