import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "duration", "position", "playButton", "autoplayButton"]

  static values = {
    playIcon: String,
    pauseIcon: String,
    autoplayOnIcon: String,
    autoplayOffIcon: String,
  }

  connect() {
    this.playerTarget.addEventListener("loadedmetadata", () => {
      this.renderTimestamp(this.durationTarget, this.playerTarget.duration)
    })


    this.playerTarget.addEventListener("timeupdate", () => {
      this.renderTimestamp(this.positionTarget, this.playerTarget.currentTime)
    })

    if (this.autoplayEnabled()) {
      this.play()
    } else {
      this.autoplayButtonTarget.innerHTML = this.autoplayOffIconValue
    }
  }

  toggleAudio(event) {
    event.preventDefault()

    if (this.playerTarget.paused) {
      this.play()
    } else {
      this.pause()
    }
  }

  toggleAutoplay(event) {
    event.preventDefault()

    if (this.autoplayEnabled()) {
      this.deleteAutoplayCookie()
      this.autoplayButtonTarget.innerHTML = this.autoplayOffIconValue
      this.pause()
    } else {
      this.createAutoplayCookie()
      this.autoplayButtonTarget.innerHTML = this.autoplayOnIconValue
      this.play()
    }
  }

  play() {
    this.playerTarget.play()
    this.playButtonTarget.innerHTML = this.pauseIconValue
  }

  pause() {
    this.playerTarget.pause()
    this.playButtonTarget.innerHTML = this.playIconValue
  }

  renderTimestamp(target, rawDuration) {
    const timestamp = this.calculateTimestamp(rawDuration)
    target.innerText = timestamp
  }

  calculateTimestamp(rawDuration) {
    const duration = Math.round(rawDuration)
    const minutes = Math.floor(duration / 60)
    const seconds = duration % 60

    return this.str_pad_left(minutes, "0", 2) + ":" + this.str_pad_left(seconds, "0", 2)
  }

  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length)
  }

  createAutoplayCookie() {
    document.cookie = "autoplay_media=true"
  }

  deleteAutoplayCookie() {
    document.cookie = "autoplay_media=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  }

  autoplayEnabled() {
    return this.getCookieValue("autoplay_media") === "true"
  }

  getCookieValue(name) {
    const result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
    return result ? result.pop() : ""
  }
}
