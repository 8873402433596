import { Controller } from "@hotwired/stimulus"
import * as pipwerks from ".././pipwerks"

export default class extends Controller {
  connect() {

    this.SCORM = pipwerks.SCORM

    console.log(this.SCORM)
  }
}
