import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["elements", "errors", "button", "form", "formField"]
  static values = {
    returnUrl: String,
    secret: String
  }

  connect() {
    if (!this.hasElementsTarget) {
      console.error("data-stripe-elements-target='elements' could not be found")
    } else if (!this.hasSecretValue) {
      console.error("data-stripe-elements-secret-value has not been defined")
    }

    const stripeKey = document.querySelector("meta[name='stripe-key']").getAttribute("content")
    this.stripe = window.Stripe(stripeKey)

    this.createPaymentElement()
  }

  disconnect() {
    this.paymentElement.destroy()
  }

  createPaymentElement() {
    const options = {
      clientSecret: this.secretValue,
    }

    this.stripeElements = this.stripe.elements(options)
    this.paymentElement = this.stripeElements.create("payment")
    this.paymentElement.mount(this.elementsTarget)
  }

  // save a card for later
  confirmSetup(event) {
    const _this = this
    const elements = this.stripeElements

    this.setLoading(true)
    this.resetErrors()

    this.stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: _this.returnUrlValue,
      }
    })
      .then(result => {
        if (result.error) {
          _this.displayErrors(result.error.message)
          _this.setLoading(false)
        } else {
          // console.log("Successfully completed confirmSetup")
          // the URL that's defined in this.returnUrlValue is followed
        }
      })
  }

  // use a card to collect a payment
  confirmPayment(event) {
    const _this = this
    const elements = this.stripeElements

    this.setLoading(true)
    this.resetErrors()

    this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: _this.returnUrlValue,
      }
    })
      .then(result => {
        if (result.error) {
          _this.displayErrors(result.error.message)
          _this.setLoading(false)
        } else {
          // console.log("Successfully completed confirmPayment")
          // the URL that's defined in this.returnUrlValue is followed
        }
      })
  }

  displayErrors(message) {
    this.errorsTarget.style.display = "block"
    this.errorsTarget.textContent = message
  }

  resetErrors() {
    this.errorsTarget.style.display = "none"
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.buttonTarget.disabled = true
      this.buttonTarget.querySelector(".spinner").classList.remove("hidden")
      this.buttonTarget.querySelector(".text").classList.add("hidden")
    } else {
      this.buttonTarget.disabled = false
      this.buttonTarget.querySelector(".spinner").classList.add("hidden")
      this.buttonTarget.querySelector(".text").classList.remove("hidden")
    }
  }
}
