import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dataSource", "value"]
  static values = {
    dataSources: Array,
    options: Object,
    currentValue: String
  }

  connect() {
    const dataSource = this.dataSourceTarget.value

    if (dataSource) {
      const options = this.optionsValue[dataSource]

      this.setupValueDropdown(options)
      this.valueTarget.value = this.currentValueValue
    }
  }

  updateValueOptions() {
    const element = event.target
    const dataSource = element.value
    const options = this.optionsValue[dataSource]

    this.resetValueDropdown()
    this.setupValueDropdown(options)
  }

  resetValueDropdown() {
    this.valueTarget.innerHTML = ""
  }

  setupValueDropdown(options) {
    options.forEach(option => {
      const optionElement = document.createElement("option")
      optionElement.text = optionElement.value = option
      this.valueTarget.add(optionElement)
    })
  }
}
